
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: block;
  src: url('/assets/fonts/Roboto-ThinItalic.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: block;
  src: url('/assets/fonts/Roboto-LightItalic.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url('/assets/fonts/Roboto-Italic.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: block;
  src: url('/assets/fonts/Roboto-MediumItalic.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url('/assets/fonts/Roboto-BoldItalic.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: block;
  src: url('/assets/fonts/Roboto-BlackItalic.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url('/assets/fonts/Roboto-Thin.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('/assets/fonts/Roboto-Light.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/assets/fonts/Roboto-Regular.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url('/assets/fonts/Roboto-Medium.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url('/assets/fonts/Roboto-Bold.ttf');

}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('/assets/fonts/Roboto-Black.ttf');

}
