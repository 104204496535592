@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-RegularItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Text Book';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-Book.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-UltraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-UltraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-UltraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-UltraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-RegularItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-UltraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Text Book';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-BookItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProText-UltraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/gt-esti-pro-display/GTEestiProDisplay-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

