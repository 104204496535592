@font-face {
  font-family: 'me_quranR3HOQ';
  src: url('/assets/fonts/me_quranR3HOQ.ttf') format("truetype");
}

.quran {
  font-family: 'me_quranR3HOQ'!important;
  direction: rtl!important;
}

p.quran{
  display: block;
  padding: 10px 0;
  text-align: left;
  width: 100%;
  line-height: 2.5em;
  font-size: 30px;
}
