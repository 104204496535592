// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  //FIGMA = заголовки и текст_1:
  --ion-text-color: #4F4F4F;
  --ion-text-color-rgb: 79,79,79;

  --ion-color-primary: var(--color-primary-1);
  --ion-color-primary-rgb: 63,180,154;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #379e88;
  --ion-color-primary-tint: #52bca4;

  --ion-color-secondary: #c3922f;
  --ion-color-secondary-rgb: 195,146,47;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #ac8029;
  --ion-color-secondary-tint: #c99d44;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #F4B73A;
  --ion-color-warning-rgb: 244,183,58;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d7a133;
  --ion-color-warning-tint: #f5be4e;

  --ion-color-danger: #ea5757;
  --ion-color-danger-rgb: 234,87,87;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #ce4d4d;
  --ion-color-danger-tint: #ec6868;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #828282; //FIGMA = подписи
  --ion-color-medium-rgb: 130,130,130;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #727272;
  --ion-color-medium-tint: #8f8f8f;

  --ion-color-lightmedium: #c4c4c4; //FIGMA = icons
  --ion-color-lightmedium-rgb: 196,196,196;
  --ion-color-lightmedium-contrast: #000000;
  --ion-color-lightmedium-contrast-rgb: 0,0,0;
  --ion-color-lightmedium-shade: #acacac;
  --ion-color-lightmedium-tint: #cacaca;

  --ion-color-light: var(--color-background-gray-1);
  --ion-color-light-rgb: 248,245,232;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #dad8cc;
  --ion-color-light-tint: #f9f6ea;

  --ion-color-superlight: var(--color-background-gray-1);
  --ion-color-superlight-rgb: 252,251,246;
  --ion-color-superlight-contrast: #000000;
  --ion-color-superlight-contrast-rgb: 0,0,0;
  --ion-color-superlight-shade: #deddd8;
  --ion-color-superlight-tint: #fcfbf7;

  --ion-color-muted: #e5e5e5; //FIGMA = rectangles & lines
  --ion-color-muted-rgb: 229,229,229;
  --ion-color-muted-contrast: #000000;
  --ion-color-muted-contrast-rgb: 0,0,0;
  --ion-color-muted-shade: #cacaca;
  --ion-color-muted-tint: #e8e8e8;

  --ion-color-alttext: #a7a7a7; //FIGMA = текст 2
  --ion-color-alttext-rgb: 167,167,167;
  --ion-color-alttext-contrast: #000000;
  --ion-color-alttext-contrast-rgb: 0,0,0;
  --ion-color-alttext-shade: #939393;
  --ion-color-alttext-tint: #b0b0b0;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #4F4F4F;
  --ion-color-white-contrast-rgb: 79,79,79;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-orange: #e9891b;
  --ion-color-orange-rgb: 233,137,27;
  --ion-color-orange-contrast: #000000;
  --ion-color-orange-contrast-rgb: 0,0,0;
  --ion-color-orange-shade: #cd7918;
  --ion-color-orange-tint: #eb9532;

  --ion-border-color: var(--ion-color-muted);

  --medina-color-aqida: #91AECF;
  --medina-color-fiqh: #90C7C3;
  --medina-color-sira: #8EB692;
  --medina-color-tafsir: #CBB996;
  --medina-color-tajwid: #C98484;
  --medina-color-adab: #B68EB2;
  --medina-color-hadis: #988EB6;
}

// @media (prefers-color-scheme: dark) {
@media (prefers-color-scheme: darkest) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #a7a7a7a7;
    --ion-color-medium-rgb: 167,167,167;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #939393;
    --ion-color-medium-tint: #b0b0b0;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}




// New Design variables 2024

:root {

  // COLORS

  // brand colors
  --color-primary-1: #00CB9F;
  --color-primary-2: #21BC9D;
  --color-primary-3: #D1F3EC;

  // background colors
  --color-background-white: #ffffff;
  --color-background-gray-1: #F3F4F8;
  --color-background-gray-2: #CDD0D1;
  --color-background-green: #E1EDE6;

  // texts colors
  --color-text-black-1: rgba(0, 6, 23, 0.95);
  --color-main-text-1: #070C1C;
  --color-text-gray-1: #A7ACB1;
  --color-text-white-1: #ffffff;

  // borders colors
  --color-border-1: rgba(232, 232, 232, 0.5);

  // SIZES

  //px to rem
  --20px-in-rem: 1.25rem;

  --padding-main-borders: var(--20px-in-rem);
}
