/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~plyr/dist/plyr.css";

.h-100 {
  height: 100%;
}

.d-inline-block {
  display: inline-block !important;
}

.hidden {
  display: none !important;
}

.ion-color-muted {
  --ion-color-base: var(--ion-color-muted);
  --ion-color-base-rgb: var(--ion-color-muted-rgb);
  --ion-color-contrast: var(--ion-color-muted-contrast);
  --ion-color-contrast-rgb: var(--ion-color-muted-contrast-rgb);
  --ion-color-shade: var(--ion-color-muted-shade);
  --ion-color-tint: var(--ion-color-muted-tint);
}

.ion-color-lightmedium {
  --ion-color-base: var(--ion-color-lightmedium);
  --ion-color-base-rgb: var(--ion-color-lightmedium-rgb);
  --ion-color-contrast: var(--ion-color-lightmedium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightmedium-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightmedium-shade);
  --ion-color-tint: var(--ion-color-lightmedium-tint);
}

.ion-color-superlight {
  --ion-color-base: var(--ion-color-superlight);
  --ion-color-base-rgb: var(--ion-color-superlight-rgb);
  --ion-color-contrast: var(--ion-color-superlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-superlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-superlight-shade);
  --ion-color-tint: var(--ion-color-superlight-tint);
}

.ion-color-alttext {
  --ion-color-base: var(--ion-color-alttext);
  --ion-color-base-rgb: var(--ion-color-alttext-rgb);
  --ion-color-contrast: var(--ion-color-alttext-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alttext-contrast-rgb);
  --ion-color-shade: var(--ion-color-alttext-shade);
  --ion-color-tint: var(--ion-color-alttext-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.medina-text {
  line-height: 1.5rem;
  p {
    // margin: 0;
    &:not(:first-child) {
      margin-top: 1.5rem;
    }
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  img {
    height: auto;
  }
  big {
    font-size: 130%;
  }
}

.ion-text-thin {
  font-weight: 100;
}

.ion-text-light {
  font-weight: 300;
}

.text-color-primary {
  color: var(--ion-color-danger);
}

.text-color-danger {
  color: var(--ion-color-danger);
}

.text-color-warning {
  color: var(--ion-color-warning);
}

.text-color-success {
  color: var(--ion-color-success);
}

:focus {
  outline: none;
}

// .horizontal-scroll {
//   display: flex;
//   flex-wrap: nowrap;
//   overflow-x: auto;
//   -webkit-overflow-scrolling: touch;

//   &::-webkit-scrollbar {
//     display: none;
//   }

//   ion-card {
//     flex: 0 0 auto;
//   }
// }

ion-refresher-content {
  --color: var(--ion-color-primary) !important;
  .refresher-pulling-text,
  .refresher-refreshing-text,
  .refresher-refreshing-icon,
  .refresher-pulling-icon {
    color: var(--ion-color-primary) !important;
  }
}

ion-card {
  border-radius: 10px;
  box-shadow: 1px 1px 4px 0px rgba(51, 51, 51, 0.35);
}

ion-segment.md {
  // padding: 5px 15px;
  ion-segment-button {
    --indicator-height: 3px;
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    &::part(indicator-background) {
      border-radius: 2px 2px 0 0;
    }
  }
}

ion-button {
  text-transform: none;
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: normal;
  --ion-font-family: "GT Eesti Pro Display";
  &.button-disabled {
    filter: grayscale(1);
  }
  &.button-solid {
    //--box-shadow: 1px 1px 4px rgba(51, 51, 51, 0.35);
    --box-shadow: none;
    --border-radius: 2000px
  }
  &:not(.button-small):not(.in-toolbar) {
    --custom-padding: 0.8rem;
    --padding-top: var(--custom-padding);
    --padding-bottom: var(--custom-padding);
  }
  &.ion-color-white {
    --ion-color-contrast: var(--ion-color-medium);
  }
  img {
    max-height: 70%;
  }
}

ion-tab-button {
  --color: var(--ion-color-medium);
}

ion-chip {
  border: var(--ion-color-base) solid 2px;
  background: var(--ion-color-white);
  color: var(--ion-color-medium);
}

.button-disabled.ion-color-white {
  --ion-color-base: var(--ion-color-muted);
}

// ion-toolbar{
//   --color: var(--ion-color-medium);
// }

ion-header {
  ion-buttons {
    color: var(--ion-color-lightmedium);
  }
  ion-title {
    font-weight: 300;
    font-size: 1.5rem;
    letter-spacing: normal;
  }
  ion-searchbar {
    --cancel-button-color: var(--ion-color-lightmedium) !important;
    --clear-button-color: var(--ion-color-lightmedium) !important;
    --icon-color: var(--ion-color-lightmedium) !important;
  }
}

//   *::part(backdrop), ion-backdrop{
//    opacity: 1 !important;
//    background-color: rgba(0,0,0,0.3);
//    backdrop-filter: blur(2px);
//  }

.ratio {
  position: relative;
  width: 100%;
  &::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: "";
  }
  &.ratio-4x3 {
    --aspect-ratio: calc(3 / 4 * 100%);
  }
  &.ratio-16x9 {
    --aspect-ratio: calc(9 / 16 * 100%);
  }
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

ion-item.item-has-focus {
  --highlight-background: var(--ion-color-primary);
}

.plyr {
  --plyr-color-main: var(--ion-color-primary);
}

svg.leader-line {
  g > use {
    mask: unset !important;
  }
}
body.backdrop-no-scroll {
  svg.leader-line {
    display: none;
  }
}

.__jivoMobileButton,
#jvlabelWrap {
  //do not show JivoSite floating buttons
  display: none !important;
}

jdiv[class^="headerBox_"] {
  //add padding-top to JivoSite:
  margin-top: var(--ion-safe-area-top);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  //fixes autofilled input background to transparent
  -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.alert-text-start .alert-message {
  text-align: start;
}

ion-col.col-cards {
  max-width: 434px !important;
}

.grid-full-height {
  min-height: 100%;
  display: flex;
  flex-flow: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.button-fake {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  text-decoration: none;
  box-shadow: 1px 1px 4px #33333359;
  border-radius: 2000px;
  padding: 0.8rem 1.4rem;
}

.modal_80x50 {
  align-items: center;
  justify-content: center;
  --min-height: 85%;
  --min-width: 90%;
  --max-height: 85%;
  --max-width: 90%;

  &::part(content) {
    border-radius: 14px;
    overflow: auto;

    box-shadow: 2px 1px 11px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 600px) {
  .modal_80x50 {
    --min-height: 800px;
    --min-width: 370px;
    --max-height: 800px;
    --max-width: 370px;
  }
}
@media (max-height: 825px) {
  .modal_80x50 {
    --min-height: 85%;
    --max-height: 85%;
  }
}

@media (min-height: 825px) {
  .modal_80x50 {
    --min-height: 701px;
    --max-height: 701px;
  }
}

//@media (max-height: 900px) {
//  .modal_80x50 {
//    --min-height: 85%;
//    --max-height: 85%;
//  }
//}

.modal_share {
  //display: inline-block;
  align-items: center;
  justify-content: center;
  --min-height: 450px;
  --min-width: 90%;
  --max-height: 450px;
  --max-width: 90%;

  &::part(content) {
    border-radius: 14px;
    overflow: auto;

    box-shadow: 2px 1px 11px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 380px) {
  .modal_share {
    --min-width: 370px;
    --max-width: 370px;
  }
}

.modal-settings {
  --min-height: 300px;
  --min-width: 300px;
  --max-height: 300px;
  --max-width: 300px;

  &::part(content) {
    border-radius: 12px;
    overflow: auto;
    box-shadow: 2px 1px 11px 0px rgba(0, 0, 0, 0.25);
  }

  //.modal-wrapper{
  //  border-radius: 12px;
  //  overflow: auto;
  //
  //  box-shadow: 2px 1px 11px 0px rgba(0, 0, 0, 0.25);
  //}
}

.sheet_modal {
  &::part(content) {
    overflow: auto;
    border-radius: 12px;
    box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

.modal_courses_search {
  align-items: center;
  justify-content: center;
  --min-height: 100%;
  --min-width: 100%;
  --max-height: 100%;
  --max-width: 100%;
}
